* {
	margin: 0;
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.loading-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loading {
	width: 10vw;
	height: 10vw;
	border: solid 10px #79a0cd;
	border-radius: 50%;
	border-top-color: transparent;
	/* border-right-color: transparent; */
	border-bottom-color: transparent;
	/* border-left-color: transparent; */
	transition: all 0.5s ease-in;
	animation-name: rotate;
	animation-duration: 1.0s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.logo {
	position: absolute;
	width: 5vw;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@media only screen and (max-width: 600px){
	.loading {
    margin-top: -35vw;
		width: 30vw;
		height: 30vw;
		border: solid 10px #79a0cd;
		border-radius: 50%;
		border-top-color: transparent;
		/* border-right-color: transparent; */
		border-bottom-color: transparent;
		/* border-left-color: transparent; */
		transition: all 0.5s ease-in;
		animation-name: rotate;
		animation-duration: 2.0s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;

		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.logo {
    margin-top: -35vw;
		position: absolute;
		width: 15vw;
	}
}
