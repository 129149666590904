

._btnbanner_ > .bp3-button {
  width: 12rem;
  border-radius: 4rem;
  /* border: none !important; */
}

/* for mobile view */
@media screen and (max-width: 30em) and (min-width: 10em) {

  ._landingImageDigitalrxMobile_ {
    display: block !important;
  }

  ._landingImageDigitalrx_{
    display: none;
  }

  /* ._landingImageDigitalrxMobile_{
    background-image: url('../resources/digitalRXoverview/RXhomePractice.jpg');;
  } */
  
  /* .center {
    margin-left: "5%" !important;
    margin-right: "5%" !important;
  } */
  ._homePractice_ {
    background-image: url('../resources/digitalRXoverview/RXhomePractice.jpg');
    background-size: cover;
  }

  .img-holder {position: relative;}
  .img-holder ._link_ {
    position: absolute;
    bottom: 5% !important; /*your button position*/
    right: 39% !important; /*your button position*/
    border-radius: 4rem;
    width: 6rem !important;
    background-color: transparent;
    padding: .15rem !important;
    text-decoration: none;
    color: white;
    font-size: .7rem !important;
  }

  ._docQuote_ {
    background-image: url('../resources/mobDocQuote.jpg') !important;
    background-size: cover !important;
  }
}

/* for tab view */
@media screen and (max-width: 60em) and (min-width: 30em) {
  ._docQuote_ {
    background-image: url('../resources/laptopImage.jpg');
    background-size: cover !important;
  }
  .img-holder {position: relative;}
  .img-holder ._link_ {
    position: absolute;
    bottom: 7% !important; /*your button position*/
    right: 40% !important; /*your button position*/
    border-radius: 4rem;
    padding: .25rem !important;
    width: 10rem !important;
    background-color: transparent;
    text-decoration: none;
    color: white;
    }
}

._docQuote_ {
  background-image: url('../resources/laptopImage.jpg');
  background-size: cover;
}

._homePractice_ {
  background-image: url('../resources/homepractice.jpg');
  background-size: cover;
  
  /* height: inherit; */
}

.img-holder {position: relative;}
.img-holder ._link_ {
    position: absolute;
    bottom: 10%; /*your button position*/
    right: 45%; /*your button position*/
    border-radius: 4rem;
    width: 8rem;
    background-color: transparent;
    padding: .5rem;
    text-decoration: none;
    color: white;
    }
/* 
@media screen and (min-width: 609) and (max-width: 768) {
  .img-holder {position: relative;}
  .img-holder ._link_ {
    bottom: 22% !important;
    font-size: 2rem !important;
  }
}

@media screen and (min-width: 320) and (max-width: 609) {
  .img-holder {position: relative;}
  .img-holder ._link_ {
    bottom: 22% !important;
    font-size: 2rem !important;
  }
} */

._landingImageDigitalrxMobile_{
  display: none;
}