div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

.link:link, .link:visited {
  transition: color .15s ease-in;
}

.link:hover {
    transition: color .15s ease-in;
}

.link:active {
    transition: color .15s ease-in;
}

.link:focus {
    transition: color .15s ease-in;
    outline: 1px dotted currentColor;
}
ul {
  flex-wrap: wrap;
}

.bp3-control-group>*{
  flex-shrink: none !important;
}

@media only screen and (min-width: 350px) and (max-width: 1440px) {
  .bp3-control-group>*{
    flex-shrink: none !important;
  }
}
