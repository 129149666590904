@font-face {
  font-family: 'Gotham';
  font-style: normal;
  src: local('Gotham'), url(./Fonts/GothamBook.ttf) format('opentype');
}

@font-face {
  font-family: 'GothamBold';
  font-style: normal;
  src: local('GothamBold'), url(./Fonts/GothamBold.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamLight';
  font-style: normal;
  src: local('GothamLight'), url(./Fonts/GothamLight.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamBook';
  font-style: normal;
  src: local('GothamBook'), url(./Fonts/GothamBook.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamThin';
  font-style: normal;
  src: local('GothamThin'), url(./Fonts/Gotham-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'GothamMedium';
  font-style: normal;
  src: local('GothamMedium'), url(./Fonts/GothamMedium.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamBlack';
  font-style: normal;
  src: local('GothamBlack'), url(./Fonts/Gotham-Black.otf) format('opentype');
}

@font-face {
  font-family: 'HindiSiliguriBold';
  font-style: normal;
  src: local('HindiSiliguriBold'), url(./Fonts/HindSiliguri-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'HindiSiliguriRegular';
  font-style: normal;
  src: local('HindiSiliguriRegular'), url(./Fonts/HindSiliguri-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'HindiSiliguriSemiBold';
  font-style: normal;
  src: local('HindiSiliguriSemiBold'), url(./Fonts/HindSiliguri-SemiBold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Gotham';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


.btn_:hover {
  color: white;
  background-color: #E93541;
}

input, button, textarea, :focus {
  outline: none;
}
.font_family2 {
  font-family: 'HindiSiliguriBold !important';
}