._sevenImg_{
  /* height: 5rem !important;
  width: 5rem !important; */
  max-width: 7rem;
}

.bp3-card {
  height: 18rem !important;
}

.center {
  margin-left: auto;
  margin-right: auto;
}
/* 
@media screen and (max-width: 600px) and (min-width: 320px) {
  .bp3-card {
    height: 24rem !important;
    width: 100% !important;
  }
} */

@media screen and (max-width: 1220px) {
  .bp3-card {
    height: 24rem !important;
    width: 100% !important;
  }
}
