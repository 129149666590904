._bg-package_{
  background-color: #E93541;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
}
._td_{
  text-decoration: none !important;
}
._white_{
  color: white !important;
}
._shadow_{
  border-bottom: 1px solid lightgray;
  text-align: center;
}


._plan1_, ._plan2_{
  width: 48% !important;
}
@media (max-width: 600px) {
  ._plan1_, ._plan2_{
    width: 98% !important;
    margin-bottom: 2% !important;
  }
  ._plan1_{
    margin-left: 0% !important;
    
  }
}
@media (min-width: 610px) {
  ._plan1_, ._plan2_{
    margin-left: 1% !important;
  }

}